import React, { useEffect, useState } from 'react'
import { Route, redirect, Routes, Navigate } from 'react-router-dom'
import DashboardPage from './components/dashboard/DashboardPage'
import LoginPage from './components/login/LoginPage'
import HomePage from './components/HomePage'
import { auth } from './firebase/firebase'
import AdminTools from './tools/AdminTools'

const MainApp = () => {

  const [isSignedIn, setIsSignedIn] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    auth.onAuthStateChanged(user => {
      setIsSignedIn(!!user);
      setLoading(false);
    });
  }, []);

  if (loading) return <div>Loading...</div>;


  return (
    <div>
      <Routes>
      <Route path="/" element={isSignedIn ? <HomePage /> : <Navigate replace to="/login" />} />
        <Route path="/login" element={!isSignedIn ? <LoginPage /> : <Navigate replace to="/" />} />
        <Route path="/child/:docId" element={isSignedIn ? <DashboardPage /> : <Navigate replace to="/login" />} />
        <Route path="/tools" element={isSignedIn ? <AdminTools /> : <Navigate replace to="/login" />} />
      </Routes>
    </div>
  )
}

export default MainApp