import React, { useEffect, useState } from 'react'
import { Autocomplete, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, Typography } from "@mui/material"
import FileCopyIcon from '@mui/icons-material/FileCopy';
import Logout from '../../signinwithgoogle/logout'
import { useParams } from 'react-router-dom'
import { doc, getDoc, setDoc, updateDoc } from 'firebase/firestore';
import { auth, db } from '../../firebase/firebase';
import { Button } from '@mui/material';
import styled from 'styled-components';
import "./dashboard.css"
import SuccessAlert from '../alert/SuccessAlert';
import moment from 'moment';
import { getIntercomStatus } from '../../common/intercom/data-mapping';
import { useSnackbar } from '../../alert/SnackbarProvider';

const StyledButton = styled(Button)`
&& {
  border-radius: 16px;
  transition: background-color 0.3s ease; 
  
  &:hover {
    background-color:rgba(220, 115, 76, 1);
  }
}
`;

const StyledTypographyButton = styled(Typography)`
  color: rgba(255, 255, 255, 1);
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 400;
  padding-top: 4px;
  padding-bottom: 4px;
  text-align :center;
`;

let journeyOptions = [
  {
    label: "Loading.."
  }
];

const planManaged = [
  { label: "Yes" },
  { label: "No" }
];

const planPreApproval = [
  { label: "Yes" },
  { label: "No" }
];

const DashboardPage = () => {

  const { docId } = useParams();
  const [childData, setChildData] = useState(null);
  const [parentData, setParentData] = useState(null);
  const [childError, setChildError] = useState('');
  const [loading, setLoading] = useState(true);
  const [showSuccess, setShowSuccess] = useState(false);
  const [intercomJourney, setIntercomJourney] = useState('')

  // Dialogs
  const [openSwitchEmail, setOpenSwitchEmail] = useState(false);
  const [openWelcomeEmail, setOpenWelcomeEmail] = useState(false);
  const [openMarkAsMember, setOpenMarkAsMember] = useState(false);

  const [openPlanAccessEmail, setOpenPlanAccessEmail] = useState(false);

  const { openSnackbar } = useSnackbar();

  // Change Data
  const [trigger, setTrigger] = useState(false);

  const handleClickOpenSwitchEmail = () => {
    setOpenSwitchEmail(true);
  };

  const handleClickOpenPlanAccessEmail = () => {
    setOpenPlanAccessEmail(true);
  };

  const handleClickCloseSwitchEmail = () => {
    setOpenSwitchEmail(false);
  };

  const handleClickOpenWelcomeEmail = () => {
    setOpenWelcomeEmail(true);
  };

  const handleClickCloseWelcomeEmail = () => {
    setOpenWelcomeEmail(false);
  };

  const handleClickOpenMarkAsMember = () => {
    setOpenMarkAsMember(true);
  }

  const handleClickCloseMarkAsMember = () => {
    setOpenMarkAsMember(false);
  }

  const handleClickClosePlanAccessEmail = () => {
    setOpenPlanAccessEmail(false);
  }

  // Handle Sending Emails
  const handleSendSwitchEmail = async () => {
    // Get Current State of switch email
    setOpenSwitchEmail(false)
    // planSwitchEmail
    if(childData) {
      // Child Data is Available
      if(childData?.planSwitchEmail) {
        // Plan Swtich Email is already true

        // Make is false and true
        // Update the document
        const childDocRef = doc(db, 'fl_content', docId)
        try {
          await updateDoc(childDocRef, {
            planSwitchEmail: false
          })

          await updateDoc(childDocRef, {
            planSwitchEmail: true
          })

         
          openSnackbar('Success - Email Sent')
          console.log('Plan Switch Email Updated')
        } catch(err) {
          openSnackbar('Failed - Unable to Sent Email')
          console.log(err)
        }
      } else {
        // Set plan Switch Email true
        // Update the document
        const childDocRef = doc(db, 'fl_content', docId)
        try {
          await updateDoc(childDocRef, {
            planSwitchEmail: true
          })
          openSnackbar('Success - Email Sent')
          console.log('Plan Switch Email Updated')
        } catch(err) {
          openSnackbar('Failed - Unable to Sent Email')
          console.log(err)
        }
      }
    }
  }

   // Handle Sending Emails
   const handleSendWelcomeEmail = async () => {
    // Get Current State of switch email
    // welcomeManualMember
     setOpenWelcomeEmail(false)
     if(childData) {
       // Child Data is Available
       if(childData?.welcomeManualMember) {
         // Welcome Email is already true
 
         // Make is false and true
         // Update the document
         const childDocRef = doc(db, 'fl_content', docId)
         try {
           await updateDoc(childDocRef, {
            welcomeManualMember: false
           })
 
           await updateDoc(childDocRef, {
            welcomeManualMember: true
           })
           openSnackbar('Success - Email Sent')
 
           console.log('Welcome Email Updated')
         } catch(err) {
          openSnackbar('Failed - Unable to Sent Email')
           console.log(err)
         }
       } else {
         // Set Welcome Email true
         // Update the document
         const childDocRef = doc(db, 'fl_content', docId)
         try {
           await updateDoc(childDocRef, {
            welcomeManualMember: true
           })
           openSnackbar('Success - Email Sent')
           console.log('Welcome Email Updated')
         } catch(err) {
          openSnackbar('Failed - Unable to Sent Email')
           console.log(err)
         }
       }
     }
  }

  // Handle Mark as Member
  const handleMarkAsMember = async () => {
    // Get Current State of switch email
    // memberManual
     setOpenMarkAsMember(false)
     if(childData) {
       // Child Data is Available
       if(childData?.memberManual) {
        // Already a Member Do Nothing
       } else {
         // Set memberManual to true
         const childDocRef = doc(db, 'fl_content', docId)
         try {
           await updateDoc(childDocRef, {
            memberManual: true
           })
           openSnackbar('Success - Member Manual Updated')
           // Update State Data
           setTrigger(current => !current);
           console.log('Member Manual Updated')
         } catch(err) {
          openSnackbar('Failed - Unable to Update Member Manual')
           console.log(err)
         }
       }
     }
  }

   //copy the email
   const handleCopyToClipboard = (emailText) => {
    navigator.clipboard.writeText(emailText)
      .then(() => {
        console.log('Text copied to clipboard', emailText);

        setShowSuccess(true);
        setTimeout(() => setShowSuccess(false), 3000);

      })
      .catch((err) => {
        console.error('Unable to copy text to clipboard', err);
      });
  };

  useEffect(() => {

    let childDocRef = doc(db, 'fl_content', docId)
    


    getDoc(childDocRef).then((snap) => {
      if (snap.exists()) {
        let childDataSnap = snap.data()
        setChildData(childDataSnap)
        console.log('Child Document data:', snap.data())
        // Fetch Parent Document
        console.log(snap.data().userId)
        // let parentDocRef = doc(db, 'fl_content', snap.data().userId)

        getDoc(snap.data().userId).then((snap) => {
          if (snap.exists()) {
            let parentDataSnap = snap.data()
            setParentData(parentDataSnap)
            setLoading(false)
            // Get Intercom Status
            let intercomStatus = getIntercomStatus(parentDataSnap, childDataSnap)
            console.log('Intercom Status:', intercomStatus)
            setIntercomJourney(intercomStatus)
            console.log('Parent Document data:', snap.data())
          } else {
            console.log('No such document!')
          }
        })
        .catch((error) => {
          console.log('Error getting document:', error)
        })
        setChildError('')
      } else {
        setChildError('Document does not exist!')
        console.log('No such document!')
      }
    }).catch((error) => {
      console.log('Error getting document:', error)
    })


  }, [docId, trigger])

  const handleClickPlanAccessEmail = async () => {
    console.log('Send Plan Access Email')
      // planAccessTrouble
      setOpenPlanAccessEmail(false)
      if(parentData) {
        // Child Data is Available
        if(parentData?.planAccessTrouble) {
          // Welcome Email is already true
  
          // Make is false and true
          // Update the document
          const parentDocRef = doc(db, 'fl_content', parentData?.id)
          try {
            await updateDoc(parentDocRef, {
              planAccessTrouble: false
            })
  
            await updateDoc(parentDocRef, {
              planAccessTrouble: true
            })
            openSnackbar('Success - Email Sent')
  
            console.log('Welcome Email Updated')
          } catch(err) {
           openSnackbar('Failed - Unable to Sent Email')
            console.log(err)
          }
        } else {
          // Set Welcome Email true
          // Update the document
          const parentDocRef = doc(db, 'fl_content', parentData?.id)
          try {
            await updateDoc(parentDocRef, {
              planAccessTrouble: true
            })
            openSnackbar('Success - Email Sent')
            console.log('Welcome Email Updated')
          } catch(err) {
           openSnackbar('Failed - Unable to Sent Email')
            console.log(err)
          }
        }
      }
  }

  if (loading) return <h1 className="title__content">Loading...</h1>; 

  return (
    <>
     {/* navbar */}
     {showSuccess && <SuccessAlert message="Copied to clipboard!" />}
     <div className="navbar__container">
        <div className="navbar__content">
          <h1 className="title__content">
           {parentData?.fullName?.split(' ')[0] || 'Unknown'}'s Dashboard
          </h1>
        </div>
        <div className="button__container">
        <StyledButton variant="contained" onClick={handleClickOpenPlanAccessEmail}>
            <StyledTypographyButton variant="h3" >
              SEND PLAN ACCESS EMAIL
            </StyledTypographyButton>
          </StyledButton>

          <StyledButton variant="contained" onClick={handleClickOpenSwitchEmail}>
            <StyledTypographyButton variant="h3" >
              Send Switch Email
            </StyledTypographyButton>
          </StyledButton>
          <StyledButton variant="contained" onClick={handleClickOpenWelcomeEmail}>
            <StyledTypographyButton variant="h3" >
              Send Welcome Email
            </StyledTypographyButton>
          </StyledButton>
          {
          !childData?.memberManual && <StyledButton variant="contained" onClick={handleClickOpenMarkAsMember}>
            <StyledTypographyButton variant="h3" >
            Mark as Member
            </StyledTypographyButton>
          </StyledButton >
        }

          <StyledButton variant="contained" onClick={async () => {
            console.log('Logout')
             auth.signOut();
          }}>
            <StyledTypographyButton variant="h3" >
              Logout
            </StyledTypographyButton>
          </StyledButton >
        </div>
      </div>

      {/* title  */}
      <div className="title__container">
        <h3 className="title__container__data">
          Parent : 
        </h3>
      </div>

      
      {/* two containers  */}
      <div className="two__container">
        {/* left container  */}
        <div className="left__container">
          <div className="left__container__box">
            <div className="name__container">
              <h4 className="left__side__fields">First Name</h4>
              <h4 className="right__side__fields__data">{parentData?.fullName?.split(' ')[0]}</h4>
            </div>
            <div className="line__container"></div>
            <div className="name__container  drop__name__container">
              <h4 className="left__side__fields">Last Name</h4>
              <h4 className="right__side__fields__data">{parentData?.fullName?.split(' ')[1]}</h4>
            </div>
            <div className="line__container"></div>
            <div className="name__container  drop__name__container">
              <h4 className="left__side__fields">Phone</h4>
              <h4 className="right__side__fields__data">{parentData.mobile_number}</h4>
            </div>
            <div className="line__container"></div>
            <div className="name__container  drop__name__container">
              <h4 className="left__side__fields">Email</h4>
              <div className="email__styles__container" onClick={() => handleCopyToClipboard(parentData.email)}>
                <h4 className="right__side__fields__data" style={{ color: 'rgba(220, 115, 76, 1)' }}>
                 {parentData.email}
                </h4>
                <FileCopyIcon sx={{
                  width: '16px',
                  height: '19px'
                }}

                />
              </div>
            </div>
            <div className="line__container"></div>
            <div className="name__container  drop__name__container">
              <h4 className="left__side__fields">Nickname</h4>
              <h4 className="right__side__fields__data">{parentData.user_name}</h4>
            </div>
            <div className="line__container"></div>
            <div className="name__container  drop__name__container">
              <h4 className="left__side__fields">Journey</h4>
              <h4 className="right__side__fields__data">{intercomJourney}</h4>
              {/* <Autocomplete
                disablePortal
                id="combo-box-demo"
                disableClearable
                options={journeyOptions.map((option) => option.label)}
                defaultValue={journeyOptions[0].label}
                onChange={(val) => {}}
                sx={{
                  width: '300px',
                  '& .MuiOutlinedInput-root': {
                    borderRadius: 12,
                    border: '2px solid rgba(165, 165, 165, 1)',
                    height: '36px',
                    alignItems: 'center',
                  },
                  '& .MuiOutlinedInput-input': {
                    fontSize: '14px',
                    fontFamily: 'Poppins',
                    fontWeight: 600,
                    color: 'rgba(165, 165, 165, 1)',
                    textAlign: 'center',
                    transform: 'translateY(-25%)',
                  },
                  '& .MuiAutocomplete-endAdornment': {
                    top: '50%',
                    transform: 'translateY(-50%)',
                  },
                }}
                renderInput={(params) => (
                  <TextField {...params} />
                )}
              /> */}
            </div>
          </div>
        </div>




        {/* right container  */}
        <div className="right__container">
          <div className="right__container__box">
            <div className="name__container">
              <h4 className="left__side__fields">First Name</h4>
              <h4 className="right__side__fields__data">{childData?.firstName || 'No First Name'}</h4>
            </div>
            <div className="line__container"></div>
            <div className="name__container  drop__name__container">
              <h4 className="left__side__fields">Last Name</h4>
              <h4 className="right__side__fields__data">{childData?.last_name || 'No Last Name'}</h4>
            </div>
            <div className="line__container"></div>
            <div className="name__container  drop__name__container">
              <h4 className="left__side__fields">Date of birth</h4>
              <h4 className="right__side__fields__data">{moment(childData?.dateOfBirth).format('DD/MM/YYYY') || 'No Date of Birth'}</h4>
            </div>
            <div className="line__container"></div>
            <div className="name__container  drop__name__container">
              <h4 className="left__side__fields">Ndis Number</h4>
              <h4 className="right__side__fields__data">{childData?.ndisNumber|| 'No NDIS Number'}</h4>
            </div>
            <div className="line__container"></div>
            <div className="name__container  drop__name__container">
              <h4 className="left__side__fields">NDIS Plan PDF URL</h4>
              <div className="email__styles__container" onClick={() => handleCopyToClipboard(childData?.ndisPlanUrl?.split('?')[0])}>
                <h4 onClick={() => {
                  let shortUrl = true;
                  if(childData?.ndisPlanUrl?.startsWith('https://firebase')) {
                  shortUrl = false;
                  }
                  if(shortUrl ? childData?.ndisPlanUrl?.split('?')[0] : childData?.ndisPlanUrl) {
                    window.open(shortUrl ? childData?.ndisPlanUrl?.split('?')[0] : childData?.ndisPlanUrl, '_blank')
                  }
                }} className="right__side__fields__data" style={{ color: 'rgba(220, 115, 76, 1)' }}>
                  {childData?.ndisPlanUrl?.startsWith('https://firebase') ? childData?.ndisPlanUrl : childData?.ndisPlanUrl?.split('?')[0] || 'No NDIS Plan URL'}
                </h4>
                <FileCopyIcon sx={{
                  width: '16px',
                  height: '19px'
                }}

                />
              </div>
            </div>
            <div className="line__container"></div>
            <div className="name__container  drop__name__container">
              <h4 className="left__side__fields">Plan managed</h4>
              <h4 className="right__side__fields__data">{childData?.plan_managed ? "Yes" : "No"}</h4>
              {/* <Autocomplete
                disablePortal
                id="combo-box-demo"
                disableClearable
                onChange={(val) => {}}
                options={planManaged.map((option) => option.label)}
                defaultValue={childData.plan_managed ? "Yes": "No"}
                sx={{
                  width: '100px',
                  '& .MuiOutlinedInput-root': {
                    borderRadius: 12,
                    border: '2px solid rgba(165, 165, 165, 1)',
                    height: '36px',
                    alignItems: 'center',
                  },
                  '& .MuiOutlinedInput-input': {
                    fontSize: '14px',
                    fontFamily: 'Poppins',
                    fontWeight: 600,
                    color: 'rgba(165, 165, 165, 1)',
                    textAlign: 'center',
                    transform: 'translateY(-25%)',
                  },
                  '& .MuiAutocomplete-endAdornment': {
                    top: '50%',
                    transform: 'translateY(-50%)',
                  },
                }}
                renderInput={(params) => (
                  <TextField {...params} />
                )}
              /> */}
            </div>
            <div className="line__container"></div>
            <div className="name__container  drop__name__container">
              <h4 className="left__side__fields">Reason for not having a plan manager</h4>
              <h4 className="right__side__fields__data">{childData?.noPlanMangerReason || 'No Reason'}</h4>
            </div>
            {/* <div className="line__container"></div>
            <div className="name__container  drop__name__container">
              <h4 className="left__side__fields">Full address</h4>
              <h4 className="right__side__fields__data">Adress 172 Glynburn Rd, Tranmere SA 5273</h4>
            </div>
            <div className="line__container"></div>
            <div className="name__container  drop__name__container">
              <h4 className="left__side__fields">Planability URL</h4>
              <div className="email__styles__container" onClick={() => handleCopyToClipboard('app.kindship.com.au/dfj23fsksa/user23')}>
                <h4 className="right__side__fields__data" style={{ color: 'rgba(220, 115, 76, 1)' }}>
                  app.kindship.com.au/dfj23fsksa/user23
                </h4>
                <FileCopyIcon sx={{
                  width: '16px',
                  height: '19px'
                }}

                />
              </div>
            </div>
            <div className="line__container"></div>
            <div className="name__container  drop__name__container">
              <h4 className="left__side__fields">Invoice Pre-Approval</h4>
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                disableClearable
                options={planPreApproval.map((option) => option.label)}
                defaultValue={'Yes'}
                onChange={(val) => {}}
                sx={{
                  width: '100px',
                  '& .MuiOutlinedInput-root': {
                    borderRadius: 12,
                    border: '2px solid rgba(165, 165, 165, 1)',
                    height: '36px',
                    alignItems: 'center',
                  },
                  '& .MuiOutlinedInput-input': {
                    fontSize: '14px',
                    fontFamily: 'Poppins',
                    fontWeight: 600,
                    color: 'rgba(165, 165, 165, 1)',
                    textAlign: 'center',
                    transform: 'translateY(-25%)',
                  },
                  '& .MuiAutocomplete-endAdornment': {
                    top: '50%',
                    transform: 'translateY(-50%)',
                  },
                }}
                renderInput={(params) => (
                  <TextField {...params} />
                )}
              />
            </div> */}
          </div>
        </div>
      </div>


      <Dialog
       open={openSwitchEmail}
       onClose={handleClickCloseSwitchEmail}
       aria-labelledby="alert-dialog-title"
       aria-describedby="alert-dialog-description">
         {/* <DialogTitle id="alert-dialog-title">{"Send Email?"}</DialogTitle> */}
         <DialogContent>
        <DialogContentText id="alert-dialog-description">
        We will send a template to help the parent switch from their current plan manager to Kindship. Do you wish to proceed?
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClickCloseSwitchEmail}>Cancel</Button>
        <Button onClick={handleSendSwitchEmail} autoFocus>
        Yes, send email
        </Button>
      </DialogActions>
       </Dialog>

       <Dialog
       open={openWelcomeEmail}
       onClose={handleClickCloseWelcomeEmail}
       aria-labelledby="alert-dialog-title"
       aria-describedby="alert-dialog-description">
         {/* <DialogTitle id="alert-dialog-title">{"Send Email?"}</DialogTitle> */}
         <DialogContent>
        <DialogContentText id="alert-dialog-description">
        We will send a plan management welcome email to help the parent inform their providers about Kindship being their new plan manager. Do you wish to proceed?
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClickCloseWelcomeEmail}>Cancel</Button>
        <Button onClick={handleSendWelcomeEmail} autoFocus>
        Yes, send email
        </Button>
      </DialogActions>
       </Dialog>


       <Dialog
       open={openMarkAsMember}
       onClose={handleClickCloseMarkAsMember}
       aria-labelledby="alert-dialog-title"
       aria-describedby="alert-dialog-description">
         {/* <DialogTitle id="alert-dialog-title">{"Send Email?"}</DialogTitle> */}
         <DialogContent>
        <DialogContentText id="alert-dialog-description">
        Are you sure you want to mark {childData?.firstName + (childData?.last_name ?? '')} as Member of Kindship?
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClickCloseMarkAsMember}>No, cancel</Button>
        <Button onClick={handleMarkAsMember} autoFocus>
        Yes, mark as member
        </Button>
      </DialogActions>
       </Dialog>


       <Dialog
       open={openPlanAccessEmail}
       onClose={handleClickClosePlanAccessEmail}
       aria-labelledby="alert-dialog-title"
       aria-describedby="alert-dialog-description">
         {/* <DialogTitle id="alert-dialog-title">{"Send Email?"}</DialogTitle> */}
         <DialogContent>
        <DialogContentText id="alert-dialog-description">
        Are you sure you want to send the email?
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClickClosePlanAccessEmail}>No, cancel</Button>
        <Button onClick={handleClickPlanAccessEmail} autoFocus>
        Yes, send email
        </Button>
      </DialogActions>
       </Dialog>
    </>
  );


  return (
    <>
    
    {/* Child Information */}
    <h3>{childError}</h3>
    {childData && (
      <div>
        <h2>Child Information</h2>
        <h3>FirstName: {childData.firstName}</h3>
        <h3>LastName: {childData.last_name}</h3>
        <h3>Date of Birth: {childData.dateOfBirth}</h3>
        <h3>NDIS Plan Url: {childData.ndisPlanUrl.split('?')[0]}</h3>
        <h3>Plan Managed: {childData.plan_managed ? "Yes": "No"}</h3>
        <h3>Reason for Not Having Plan Manager: {childData.noPlanMangerReason}</h3>
      </div>
    )}

    {/* Parent Information */}
    {parentData && (
      <div>
        <h2>Parent Information</h2>
        <h3>FirstName: {parentData.fullName.split(' ')[0]}</h3>
        <h3>LastName: {parentData.fullName.split(' ')[0]}</h3>
       <h3>Phone: {parentData.mobile_number}</h3>
       <h3>Email: {parentData.email}</h3>
       <h3>Nickname: {parentData.user_name}</h3>
       <h3>Journey: EOI</h3>
      </div>
    )}
    <Logout />
    </>
  )
}

export default DashboardPage