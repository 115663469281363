import { Typography } from '@mui/material';
import React, { useState } from 'react'
import styled from 'styled-components';
import FileInput from './FileInput';
import {ApiCaller, ApiCaller2} from './ApiCaller';

const StyledTypographyH = styled(Typography)`
  color: rgba(255, 255, 255, 1);
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 400;
  padding-top: 4px;
  padding-bottom: 4px;
  text-align :center;
`;

const AdminTools = () => {

    const [file, setFile] = useState(null);
    const [file2, setFile2] = useState(null);
    const [response, setResponse] = useState(null);
    const [response2, setResponse2] = useState(null);

    const handleFileChange = (newFile) => {
        setFile(newFile);
      };


      const handleFile2Change = (newFile) => {
        setFile2(newFile);
      }

      const handleResponse = (data) => {
        setResponse(data);
      };

      const handleResponse2 = (data) => {
        setResponse2(data);
      }

  return (
    <>
   <h3 style={{color: 'black'}}>Admin Tool (Upload CSV)</h3>

   <FileInput onFileChange={handleFileChange} />

   <br>
    </br>

    <ApiCaller file={file} onResponse={handleResponse} />

    {response && (
        <div>
          <h2>API Response:</h2>
          <pre>{JSON.stringify(response, null, 2)}</pre>
         
        </div>
      )}


      <br></br>
      <br></br>

    <h3 style={{color: 'black'}}>Planability Budget CSV (Upload CSV)</h3> 

   <FileInput onFileChange={handleFile2Change} />

   <br>
    </br>

    <ApiCaller2 file={file2} onResponse={handleResponse2} />

    {response2 && (
        <div>
          <h2>API Response:</h2>
          <pre>{JSON.stringify(response2, null, 2)}</pre>
         
        </div>
      )}

  </>
  )
}

export default AdminTools