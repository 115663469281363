// src/components/ApiCaller.js
import React, { useState } from 'react';

const ApiCaller = ({ file, onResponse }) => {
    const handleApiCall = async () => {
        const formData = new FormData();
        formData.append('csv', file);

        try {
            const response = await fetch('https://dolphin-app-zx3nc.ondigitalocean.app/budgetcsv', {
                method: 'POST',
                body: formData,
            });
            const data = await response.json();
            onResponse(data);
        } catch (error) {
            console.error('API call error:', error);
        }
    };

    return (
        <div>
            <button onClick={handleApiCall}>Upload</button>
        </div>
    );
};

const ApiCaller2 = ({ file, onResponse }) => {
    const handleApiCall = async () => {
        const formData = new FormData();
        formData.append('csv', file);

        try {
            const response = await fetch('https://dolphin-app-zx3nc.ondigitalocean.app/participant_budget_csv', {
                method: 'POST',
                body: formData,
            });
            const data = await response.json();
            onResponse(data);
        } catch (error) {
            console.error('API call error:', error);
        }
    };

    return (
        <div>
            <button onClick={handleApiCall}>Upload</button>
        </div>
    );
};

export {ApiCaller, ApiCaller2};
