import React from 'react'
import { auth } from '../firebase/firebase'

const HomePage = () => {



  return (
    <h3 style={{color: 'black'}}>You're Logged in as {auth?.currentUser?.email}</h3>
  )
}

export default HomePage