// src/components/FileInput.js
import React, { useState } from 'react';

const FileInput = ({ onFileChange }) => {
    const handleFileChange = (e) => {
        const file = e.target.files[0];
        onFileChange(file);
    };

    return (
        <div>
            <input type="file" accept=".csv" onChange={handleFileChange} />
        
        </div>
    );
};

export default FileInput;